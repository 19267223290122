/* @import url( "https://cdn.syncfusion.com/ej2/material.css"); */

/* label{
    max-width: 300px!important;
} */
@import "rsuite/dist/rsuite.css";
.e-input-group.e-error, .e-input-group.e-control-wrapper.e-error, 
.e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left)
{
    border-bottom-color: black;
}
.inputdtcp{
    margin-right: 10px!important;

}
ul{
    padding-right: 0px;
    width: 315px!important;
    /* height: 250px!important; */
    max-height: 250px!important;
}
.ui{
    z-index: 10000;
    background-color: #fff;
}
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    
    background: rgba(0, 0, 0, 0.834);
    z-index: 10000;
}

.ui.inverted.dimmer .ui.loader:after {
    border-color: #000 transparent transparent !important;
   
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
select{
    width:250px!important;

}
.imageback{
    /* width:80%; */
    /* height:100%!important; */
    /* position:relative;
    display:block;
    width:100%;
    height:400px; */
    position: absolute;
    width:100%; 
    height:60%;
    max-height: 100%; 
    background:url("\hblogo.png") center center no-repeat;
    /* background-position:cover;  */
    background-size: contain;
    opacity:0.1;
    /* overflow: hidden; */
  }
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    /* min-height: auto; */
    height: 40px;
    width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* .imageback::before{
    content:"";
    background: url("C:\React Projects\datasheet-download\src\assets\hblogo.png") no-repeat  center ;
    background-position: cover;
    background-size: 100% 100%;
   opacity:0.1;
    bottom:0;
    right:0;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:0;
    /* transform: translate(-50%,-50%); */
	/* max-width:100%;
	max-height:100%;
	overflow:auto;
  } */
   
/* .inputdt{
    margin-top: -75px!important;
} */

/* .iconset{
    background:  url(/src/assets/date.png) right no-repeat;
    margin-right: 15px;
    background-size: 20px; */
    /* padding:7px; */
   /* padding-left: -10px!important; */
/* } */

.react-datepicker-wrapper .bi .bi-calendar {
    position: absolute!important;
    top: 0!important;
    right: 0!important;
  }


@media only screen and (max-width: 600px) {
.inputdt{
    /* padding-bottom: 150px!important; */
    margin-bottom: 20px!important;
}
.clrbtn{
    margin-top: 20px!important;
   left:0%;
}
.dbtn{
    
    left:0!important;
}


}

@media  screen and (min-width:600px) and (max-width:820px)
{
    label
    {
       max-width: 300px!important;
    }
    .dbtn{
       
        left:100%;
        
    }
    .clrbtn{
        left: 90%;
    }
    .bkdateto{
        margin-top: 10px!important;
    }
    
   
    
}
.demo {
    background: #5d9cec;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .demo button {
    background-color: #4a89dc;
    border: 0;
    color: #fff;
    padding: 10px 15px;
    cursor: pointer;
  }
.active{
    position:absolute;
    /* cursor:not-allowed; */
    z-index: 1000;
    background: #fff;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    
    
}

@media  screen and (min-width:640px){
    .paddlr {
borderRight: "10px solid #fff";
borderLeft: "10px solid #fff";
    }
}
